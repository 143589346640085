import { template as template_eedc9c43966443c98ca5c83cf49281f2 } from "@ember/template-compiler";
const FKText = template_eedc9c43966443c98ca5c83cf49281f2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
