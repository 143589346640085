import { template as template_4a3aaf0ca1db420bb80d460d8667d90a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_4a3aaf0ca1db420bb80d460d8667d90a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
